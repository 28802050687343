export default () => {
    const getEmoji = [
        { img: require('../../assets/emoji/Neutral.png'), name: 'neutral' },
        { img: require('../../assets/emoji/Happy.png'), name: 'happy' },
        { img: require('../../assets/emoji/Sad.png'), name: 'sad' },
        { img: require('../../assets/emoji/Surprise.png'), name: 'surprise' },
        { img: require('../../assets/emoji/Fear.png'), name: 'fear' },
        { img: require('../../assets/emoji/Disgust.png'), name: 'disgust' },
        { img: require('../../assets/emoji/Anger.png'), name: 'anger' },
        { img: require('../../assets/emoji/Contempt.png'), name: 'contempt' },
    ];
    return getEmoji;
}