import React from 'react';
import MainNavigation from './src/common/MainNavigation';
import { Provider as PaperProvider } from 'react-native-paper';
import { UserProvider } from './src/stores/UserContext';

const App = () => {
    return (
        <UserProvider>
            <PaperProvider>
                <MainNavigation />
            </PaperProvider>
        </UserProvider>
    );
}

export default App;

