import React, { useReducer, useEffect} from "react";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { config } from "../../config";

const api = axios.create({
    baseURL: `${config.prot[config.index]}://${config.host[config.index]}${config.port[config.index]}/`,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
    }
});

const UserContext = React.createContext();

const initialUserState = {
    isLoggedIn: false
};

const userReducer = ( state, action ) => {
    switch (action.type) {
        case 'prevUser':
            return {
                prev_Id: action.payload._id,
                prevId: action.payload.id,
                isLoggedIn: false
            }
        case 'loginUser':
            return {
                _id: action.payload._id,
                id: action.payload.id,
                jwt: action.payload.jwt,
                isLoggedIn: true,
                quantity: 186,
            }
        case 'logoutUser':
            return {
                isLoggedIn: false
            }
        case 'newSession':
            return {
                ...state,
                sessionToken: action.payload.token,
                sessionType: action.payload.type,
                memoryTimeout: 1000,
            }
        case 'loadImages':
            return {
                ...state,
                tests: action.payload.map((e) => {
                    return {
                        _id: e._id,
                        file: e.fileName,
                        base64: e.base64,
                        options: (e.selection) ? e.selection : null,
                        distance: (e.distance) ? e.distance : null,
                        type: (e.type) ? e.type : null,
                        e_id: (e.e_id) ? e.e_id : null,
                        e_base64: (e.e_base64) ? e.e_base64 : null,
                    }                   
                }),
            }
        case 'clearImages':
            return {
                ...state,
                tests: {}
            }
        case 'setResults':
            return {
                ...state,
                recent: action.payload
            }
    }
}

export const UserProvider = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, initialUserState);

    const getData = async () => {
        try {
            const id = await AsyncStorage.getItem('@ID');
            const _id = await AsyncStorage.getItem('@_ID');
            dispatch({type: 'prevUser', payload: { id, _id }})
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const loginUser = (unique, id, callback) => {
        const sendData = {
            params: {
                _id: id,
                id: unique
            }
        };
        api.get('participant', sendData).then((res) => {
            dispatch({
                type: 'loginUser',
                payload: {
                    _id: res.data._id,
                    id: res.data.identification,
                    jwt: res.data.token
                }
            });
            try {
                AsyncStorage.setItem('@ID', unique);
                AsyncStorage.setItem('@_ID', id);
            } catch (e) {
                console.log(e.message);
            }
            if (callback) {
                callback();
            }
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const logoutUser = () => {
        dispatch({
            type: 'logoutUser'
        })
    }

    const registerUser = (age, sex, asd, other, callback) => {
        const sendData = {
            age: age,
            sex: sex,
            asd: (asd) ? 1 : 0,
            other: other
        }
        api.post('participant', sendData).then((res) => {
            dispatch({
                type: 'loginUser',
                payload: {
                    _id: res.data._id,
                    id: res.data.identification,
                    jwt: res.data.token
                }
            });
            if (callback) {
                callback();
            }
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const getHeader = () => {
        return { headers: { Authorization: `Bearer ${state.jwt}` } }
    };

    const getSessionHeader = () => {
        return {
            headers: { Authorization: `Bearer ${state.sessionToken}` },
        }
    }

    const createSession = (type, callback) => {
        api.post('session', {type: type, qty: state.quantity}, getHeader()).then((res) => {
            if (res) {
                dispatch({ type: 'newSession', payload: { token: res.data.token, type: type } });
                if (callback) {
                    callback(type);
                }
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const getImageSamples = (callback) => {
        api.get('images', getSessionHeader()).then((res) => {
            dispatch({ type: 'loadImages', payload: res.data}); 
        }).then(() => {
            if (callback) callback();
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const getMemorySamples = (callback) => {
        api.get('images/memory', getSessionHeader()).then((res) => {
            dispatch({ type: 'loadImages', payload: res.data}); 
        }).then(() => {
            if (callback) callback();
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const setTestSelection = (imgId, selection, time, callback) => {
        const body = {
            selection, time, imgId
        }
        api.post('session/test', body, getSessionHeader()).then((res) => {
            if (res.status === 200) {
                if (callback) callback();
            }
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const setMemoryRes = (imgId, selection, actual, type, callback) => {
        const body = {
            selection, actual, imgId, type
        }
        api.post('session/memory', body, getSessionHeader()).then((res) => {
            if (res.status === 200) {
                if (callback) callback();
            }
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const getResults = (callback) => {
        api.post('session/results', {}, getSessionHeader()).then((res) => {
            dispatch({ type: 'setResults', payload: res.data });
            if (callback) callback();
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const clearData = (callback) => {
        dispatch({
            type: 'loginUser',
            payload: {
                _id: state._id,
                id: state.id,
                jwt: state.jwt
            }
        });
        if (callback) callback();
    }

    return (
        <UserContext.Provider
            value={{
                state: state,
                login: loginUser,
                logout: logoutUser,
                register: registerUser,
                session: createSession,
                getImages: getImageSamples,
                getMemory: getMemorySamples,
                setSelection: setTestSelection,
                setMemory: setMemoryRes,
                getResults,
                clearData,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;