import { StyleSheet } from "react-native";

const containers = StyleSheet.create({
    main: { 
        flex: 1, 
        backgroundColor: '#fff',
        alignItems: 'center',
        marginVertical: 2
    },
    form: {
        flex: -1,
        backgroundColor: '#fff',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 5,
        width: "90%",
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 5,
    },
});

export default containers;