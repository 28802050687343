import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { View, Text, Button } from "react-native";
import { Card, Paragraph, Title } from "react-native-paper";
import UserContext from "../stores/UserContext";

const Bold = ({children}) => {
    return (
        <Paragraph style={{fontWeight: 'bold'}}>{children}</Paragraph>
    )
}

const Results = ({navigation, route}) => {
    const { state, getResults, clearData } = useContext(UserContext);
    const [resultRender, setResultRender] = useState(null);
    const { path } = route.params;

    const renderResults = () => {
        if (state.sessionType === 'memory') {
            setResultRender(
                <Card>
                    <Card.Content>
                        <Title>Results for the Memory Recall Test</Title>
                        <Paragraph>Out of all the images seen you identified <Bold>{state.recent.totalCorrect} correctly</Bold> from a total of <Bold>{state.recent.testCount} images</Bold>.</Paragraph>
                        <Paragraph><Bold>Vigilance Images</Bold>: you identified <Bold>{((state.recent.vCorrect / state.recent.vCount) * 100).toFixed(1)}%</Bold></Paragraph>
                        <Paragraph><Bold>Target Images</Bold>: you identified <Bold>{((state.recent.tCorrect / state.recent.tCount) * 100).toFixed(1)}%</Bold></Paragraph>
                        <Paragraph>Your best memory recall spanned <Bold>{state.recent.lastSeenBest * -1}</Bold> images before being seen again! your average successful recall was a span of about <Bold>{state.recent.lastSeenAvg.toFixed(1) * -1} images</Bold>.</Paragraph>
                    </Card.Content>
                </Card>
            )
        } else {
            setResultRender(
                <Card>
                    <Card.Content>
                        <Title>Results for the Expressions Classification Test</Title>
                        <Paragraph>You successfully identified <Bold>{(state.recent.actual * 100).toFixed(1)}%</Bold> of the expressions correctly.</Paragraph>
                        {(state.recent.actual * 100 < 60) ? <Paragraph>This is subjective and there are no wrong or right answers</Paragraph> : null}
                        <Paragraph>On average you took <Bold>{state.recent.time.toFixed(2)} seconds</Bold> to make your selections</Paragraph>
                        {(state.recent.actual > state.recent.predicted) ? <Bold>You beat the computer!!!</Bold> : null}
                        {(state.sessionType === 'main') ? <Paragraph>The state of the art computer algorithm for the classification of expressions from still images, scored <Bold>{(state.recent.predicted * 100).toFixed(1)}%</Bold> on the same set of images.</Paragraph> : null }
                    </Card.Content>
                </Card>
            )
        }
    } 

    useEffect(() => {
        getResults();
    }, [path]);
   
    useEffect(() => {
        if (state.recent) {
            renderResults();
        }
    }, [state.recent]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            )
          });
    });

    const buttonToRender = () => {
        if (path === 'N' && state.sessionType !== 'base') {
            return <Button title="Phase TWO" onPress={() => { 
                navigation.navigate('TestEnhanced');
            }}/>
        }
        return <Button title="EXIT" onPress={() => { 
            clearData(() => { navigation.navigate('Index') }) ;
        }}/>
    }

    return (
        <View>
            {resultRender}
            {buttonToRender()}
        </View>
    );
};

export default Results;