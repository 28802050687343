import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button, Modal, Paragraph, Portal } from 'react-native-paper';
import UserContext from '../stores/UserContext';

import buttons from '../styles/buttons'

const Index = ({ navigation }) => {
    const { state, logout, session } = useContext(UserContext);
    const [visible, setVisible] = useState(false);

    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const userMessage = (state.isLoggedIn) ? `User: ${state.id}/${state._id}` : 'Sign in';

    const startSession = (name) => {
        navigation.navigate('Start', { next: 'Test', name });
    }

    const startMemory = (name) => {
        navigation.navigate('Start', { next: 'Memory', name });
    }

    if (state.isLoggedIn === false) {
        return (
            <View style={styles.container}>
                <Button style={[buttons.button, {marginTop: 25}]} labelStyle={buttons.label} mode='contained' onPress={() => navigation.navigate('Signin')}>Sign In</Button>
                <Button style={buttons.button} labelStyle={buttons.label} mode='contained' onPress={() => navigation.navigate('Register')}>Register</Button>
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <Paragraph>You are signed in</Paragraph>
                <Paragraph style={{fontSize: 24, padding: 5}}>{userMessage}</Paragraph>
                <Button 
                    style={[buttons.button, {marginTop: 15, backgroundColor: 'purple'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={() => {
                        session('base', startSession);
                    }}
                >Baseline Test</Button>
                
                <Button 
                    style={[buttons.button, {marginTop: 25, backgroundColor: 'green'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={() => {
                        session('main', startSession);
                    }}
                >Main Test</Button>

                <Button 
                    style={[buttons.button, {marginTop: 25, backgroundColor: 'blue'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={() => {
                        session('memory', startMemory);
                    }}
                >Memory Test</Button>

                <Button 
                    style={[buttons.button, {marginTop: 50, backgroundColor: 'red'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={showModal}
                >SignOut</Button>
                
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal}>
                        <Button 
                            style={[buttons.button, {marginTop: 50, backgroundColor: 'red'}]} 
                            labelStyle={buttons.label} 
                            mode='contained' onPress={() => {
                                hideModal();
                                logout();
                            }}
                        >Are you sure?</Button>
                    </Modal>
                </Portal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }, UserText: {
        paddingVertical:25,
        fontSize: 24,
    }
});

export default Index;