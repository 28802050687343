import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { View, Pressable, Image, StyleSheet, Text, Button, useWindowDimensions } from 'react-native';
import { Card, Paragraph, Title, Surface, ActivityIndicator, Colors } from 'react-native-paper';
import * as Speech from 'expo-speech';
import { FontAwesome } from '@expo/vector-icons';

import getEmoji from '../components/getEmoji';
import UserContext from '../stores/UserContext';

import {config} from '../../config';
import { SafeAreaView } from 'react-native-safe-area-context';

const TestEnhanced = ({navigation}) => {
    const { state, getImages, setSelection } = useContext(UserContext);
    const [test, setTest] = useState(0);
    const [total, setTotal] = useState(0);
    const [timer, setTimer] = useState(0);
    const [currentSelection, setCurrentSelection] = useState(-1);
    const [time, setTime] = useState(0);
    const [exit, setExit] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const width = useWindowDimensions().width;
    const height = useWindowDimensions().height;
    const respStyle = { 
        width: (width < height) ? (width / 10 * 9): 'auto',
        height: (width < height) ? 'auto': (height / 10 * 5),
    }

    const changeSelection = (selection) => {
        Speech.speak(emojis[selection].name);
        setTime((new Date() - timer) / 1000);
        setCurrentSelection(selection);
    }

    const recordResult = () => {
        setSelection(state.tests[test].e_id, currentSelection, time, nextTest);
    }

    const nextTest = () => {
        if (test + 1 < total) {
            setCurrentSelection(-1)
            setTimer(new Date());
            setTime(0)
            setTest(test + 1);
        } else {
            navigation.navigate('Results', { path: 'E'});
        }
    }

    const emojis = getEmoji();

    useEffect(() => {
        if (state.tests !== undefined) {
            setTotal(state.tests.length); 
            setTimer(new Date());
            setIsReady(true);
        }
    }, [state.tests]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            ),
            headerShown: false
          });
    })

    if (state.tests === undefined && !isReady) {
        return (
            <SafeAreaView style={styles.loadingContainer}>
                <ActivityIndicator animating={true} color={Colors.blue800} size={250} />
                <Text style={{fontSize: 72}} color={Colors.blue900}>Loading...</Text>
                <Button style={{marginTop:150}} title="CANCEL" onPress={() => navigation.navigate('Index')} />
            </SafeAreaView>
        );
    } 

    return (
        <SafeAreaView style={{flex: 1}}>
            <Card style={{maxHeight: height}}>
                {
                    (exit) ? <Button title="EXIT" onPress={() => navigation.pop() } /> : null
                }
                
                <Card.Content>
                    <Pressable onLongPress={() => setExit(!exit)} delayLongPress={1000}>
                        <Title>Image {test + 1} out of {total}</Title>
                        <Card.Cover  
                            style={[styles.heroImage, respStyle]}
                            source={{uri: `data:image/jpeg;base64,${state.tests[test].e_base64}`}}
                            // source={{uri: `${config.prot[config.index]}://${config.host[config.index]}${config.port[config.index]}/img/normal/${state.tests[test].file}`}} 
                        />
                    </Pressable>
                    <Pressable onPress={() => {
                        nextTest();
                    }}>
                        <Paragraph style={styles.passOptions}>Not Sure</Paragraph>
                    </Pressable>
                    <Card.Actions style={styles.choiceContainer}>
                        {
                            state.tests[test].options.map((ind) => {
                                return (
                                    <Pressable 
                                        style={styles.choiceElement} key={ind} 
                                        onPress={() => {
                                            changeSelection(ind)
                                        }}
                                    >
                                        <Surface style={[styles.surfaceStyle, (ind === currentSelection) ? styles.selectedSurface : null]}>
                                            <Image style={styles.imgElement} source={emojis[ind].img} />
                                            <Text style={styles.txtElement}>{emojis[ind].name.toUpperCase()}</Text>
                                        </Surface>
                                    </Pressable>
                                )
                            })
                        }
                    </Card.Actions>
                    {
                        (currentSelection !== -1) ? <Pressable style={styles.acceptButton} onPress={() => recordResult()}>
                            <FontAwesome name="check-circle" size={100} color="green" />
                        </Pressable> : null
                    }
                </Card.Content>
            </Card>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }, 
    heroImage: {
        aspectRatio: 1/1, 
        // width: '90%', 
        // height: 'auto',
        alignSelf: 'center'
    },
    choiceContainer: {
        flexDirection: 'row',
        borderTopWidth: 1,
        // marginTop: 25
    },
    choiceElement: {
        flex: 1,
        marginTop: 15,
    },
    imgElement: {
        aspectRatio: 1/1,
        width: 100,
        height: "auto",
        alignSelf: 'center',
    },
    txtElement: {
        marginTop: 15,
        fontSize: 16,
        alignSelf: 'center'
    },
    passOptions: {
        alignSelf: 'flex-end',
        color: 'red',
        fontSize: 20,
        marginVertical:20,
        marginRight: 20
    }, 
    surfaceStyle: {
        borderWidth: 1,
        borderColor: 'lightgrey',
        elevation: 9,
        padding: 8,
    },
    selectedSurface: {
        backgroundColor: 'lightgreen',
    },
    acceptButton: {
        alignSelf: 'center'
    }
});

export default TestEnhanced;
