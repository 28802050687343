export const config = {
    index: 2,
    prot: [
        'http',
        'http',
        'https',
    ],
    host: [
        '192.168.0.78',
        '10.144.127.178',
        'exp-api.johnson7.co.uk'
    ],
    port: [
        ':5001',
        ':5001',
        ''
    ]
}