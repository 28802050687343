import { Animated, Easing } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useEffect } from 'react';

const FontAwesomeSpin = ({name, size, color}) => {

    let spinValue = new Animated.Value(0);

    let spin = () => {
        spinValue.setValue(0);
        Animated.timing(
            spinValue, {
                toValue: 1,
                duration: 1000,
                easing: Easing.linear,
                useNativeDriver: true
            }
        ).start(() => spin());
    };

    const rotate = spinValue.interpolate({inputRange: [0, 1], outputRange: ['0deg', '360deg']});

    useEffect(() => {
        spin();
    }, [])

    return (
        <Animated.View style={{transform: [{rotate}]}}>
            <FontAwesome name={name} size={size} color={color} />
        </Animated.View>
    )
}

export default FontAwesomeSpin;