import { StyleSheet } from "react-native";

const buttons = StyleSheet.create({
    button: {
        width: '90%',
        height: 50,
        marginBottom: 25,
        justifyContent: 'center'
    },
    label: {
        fontSize: 24,
    }
});

export default buttons;

