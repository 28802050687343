import React, { useContext, useEffect, useState } from "react";
import { Button, StyleSheet } from "react-native";
import { ActivityIndicator, Card, Colors, Paragraph, Title } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import UserContext from "../stores/UserContext";

const Start = ({navigation, route}) => {
    const { getImages, getMemory } = useContext(UserContext);
    const { next, name } = route.params;
    const [isNotReady, setIsNotReady] = useState(true);

    const imagesReady = () =>  {
        setIsNotReady(false);
    }

    useEffect(() => {
        if (next === 'Memory') {
            getMemory(imagesReady);
        } else {
            getImages(imagesReady);
        }
    }, []);

    return (
        <SafeAreaView style={{flex: 1}}>
            <Card>
                <Card.Content>
                    <Title>Instructions for {name.toString().toUpperCase()} Test</Title>
                    <Paragraph>This is some Instructions</Paragraph>
                </Card.Content>
                <Card.Actions>
                    {
                        (!isNotReady) 
                        ?
                            <Button title="READY" disabled={isNotReady} onPress={() => navigation.navigate(next)} />
                        :
                            <SafeAreaView style={styles.loadingContainer}>
                                <ActivityIndicator animating={true} color={Colors.blue800} size={50} />
                                <Paragraph color={Colors.blue900}>Loading...</Paragraph>
                                <Button style={{marginTop:150}} title="CANCEL" onPress={() => navigation.navigate('Index')} />
                            </SafeAreaView>
                    }
                </Card.Actions>
            </Card>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }, 
})

export default Start;