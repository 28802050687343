import { StyleSheet } from "react-native";

const forms = StyleSheet.create({
    label: {
        fontSize: 24,
        width: "50%",
        justifyContent: 'flex-start',
        paddingLeft: 5
    }, 
    input: {
        fontSize: 32,
        width: "50%",
        backgroundColor: '#d8dbdb',
        padding: 16,
        justifyContent:'center'
    },
});

export default forms;