import React, { useContext, useState } from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import { TextInput, Button } from 'react-native-paper';
import RNPickerSelect from 'react-native-picker-select';
import Checkbox from 'expo-checkbox';

import containers from "../styles/containers";
import forms from "../styles/forms";
import buttons from "../styles/buttons";
import UserContext from "../stores/UserContext";

const Register = ({navigation}) => {
    const { register } = useContext(UserContext);
    const [age, setAge] = useState(null);
    const [sex, setSex] = useState(null);
    const [asd, setAsd] = useState(false);
    const [other, setOther] = useState("");
    const [error, setError] = useState('');

    const pickerStyle = StyleSheet.create({
        inputIOS: {
            ...styles.frmTextInput, 
            width: "100%",
            paddingLeft: 15,
            paddingTop: 25,
            justifyContent: 'center',
            alignSelf: 'center',
        },
        inputAndroid: {...styles.frmTextInput, width: "100%"},
    });

    const formSuccess = () => {
        navigation.navigate('Index');
    }

    const submitForm = () => {
        if (age === null && sex === null) {
            setError('Complete all fields');
        } else {
            setError('');
            register(age, sex, asd, other, formSuccess);
        }
    }

    return (
        <View style={[containers.main]}>
            <ScrollView>
                <Text style={styles.errorText}>{error}</Text>
                <Text style={styles.labelText}>How old are you ?</Text>
                <TextInput
                    mode='outlined'
                    style={{...styles.frmTextInput}}
                    label="Age"
                    placeholder="00"
                    placeholderTextColor="#60605e"
                    numeric
                    keyboardType={'numeric'}
                    returnKeyType="done"
                    maxLength={2}
                    value={age}
                    onChangeText={(v) => {
                        setAge(v)
                    }}
                />
                <Text style={styles.labelText}>Are you male or female ?</Text>
                <View style={styles.cbSection}>
                    <Checkbox
                        style={styles.checkbox}
                        value={sex == 'male'}
                        onValueChange={() => setSex('male')}
                        color={sex == 'male' ? '#4630EB' : undefined}
                    />
                    <Text>Male</Text>
                {/* </View>
                <View style={styles.cbSection}> */}
                    <Checkbox
                        style={styles.checkbox}
                        value={sex == 'female'}
                        onValueChange={() => setSex('female')}
                        color={sex == 'female' ? '#4630EB' : undefined}
                    />
                    <Text>Female</Text>
                </View>
                {/* <TextInput
                    mode='outlined'
                    style={styles.frmTextInput}
                    // label={(sex !== null) ? null : 'Sex'}
                    placeholder="M/F"
                    // value={sex}
                    render={(props) => (
                        <RNPickerSelect 
                            style={pickerStyle}
                            value={sex}
                            placeholder={{label:"Sex", value: null, color: 'lightgrey'}}
                            onValueChange={(itemValue) => setSex(itemValue)}
                            items={[
                                {label:"Male", value:"male", inputLabel: 'Male'},
                                {label:"Female", value:"female", inputLabel: 'Female'},
                            ]}
                        />
                    )}
                /> */}
                <Text style={styles.labelText}>Have you been diagnosed with Autism ?</Text>
                <View style={styles.cbSection}>
                    <Checkbox
                        style={styles.checkbox}
                        value={asd}
                        onValueChange={setAsd}
                        color={asd ? '#4630EB' : undefined}
                    />
                    <Text>Yes</Text>
                </View>
                <Text style={styles.labelText}>Do you have any other conditions that may effect the recognition of facial expressions ?</Text>
                <TextInput 
                    mode='outlined'
                    style={{...styles.frmTextInput}}
                    label="Other"
                    placeholder="Other conditions"
                    placeholderTextColor="#60605e"
                    value={other}
                    onChangeText={setOther}
                    multiline
                />
                {/* <TextInput
                    mode='outlined'
                    style={styles.frmTextInput}
                    label="Autism"
                    placeholder="ASD"
                    value={asd}
                    render={(props) => (
                        <Checkbox.Item 
                            status={asd ? 'checked' : 'unchecked'}
                            onPress={() => setAsd(!asd)}
                        />
                    )}
                /> */}
                <Button 
                    style={buttons.button} 
                    labelStyle={buttons.label} 
                    mode='contained' 
                    onPress={() => submitForm()}
                >
                    Register
                </Button>    
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    frmTextInput: {
        marginBottom: 25,
        // width: "90%",
        fontSize: 24,
        justifyContent:'center'
    }, 
    labelText: {
        alignSelf: 'flex-start',
        fontSize: 14
    },
    errorText: {
        fontSize: 24,
        marginTop: 10,
        color: 'red'
    },
    cbSection: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 25,
        // // width: "90%",
        fontSize: 24,
        justifyContent:'center'
    },
    checkbox: {
        margin: 8,
        width: 50,
        height: 50
    },
})

export default Register;