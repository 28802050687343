import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import UserContext from '../stores/UserContext';
import buttons from '../styles/buttons';

const Signin = ({navigation}) => {
    const { state, login } = useContext(UserContext);
    const [id, setId] = useState(state.prevId);
    const [_id, set_Id] = useState(state.prev_Id);
    const [error, setError] = useState('');

    const formSuccess = () => {
        navigation.navigate('Index');
    }

    const submitForm = () => {
        if (id.length === 4 && _id.length > 0) {
            setError('');
            login(id, _id, formSuccess)
        } else {
            let tmp = '';
            if (id.length < 4) {
                tmp += 'Check the first 4 digits. \n';
            }
            if (_id.length === 0) {
                tmp += 'Complete the second field.'
            }
            setError(tmp);
        }
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#fff' }}>
            <View style={styles.container}>
                <View style={styles.rowContainer}>
                    <View style={styles.leftElement}>
                        <TextInput
                            mode='outlined'
                            label='Unique Identifier'
                            style={styles.idInputStyle}
                            placeholder="000000"
                            placeholderTextColor="#60605e"
                            numeric
                            keyboardType={'numeric'}
                            maxLength={4}
                            value={id}
                            onChangeText={(v) => {
                                setId(v)
                            }}
                        />
                    </View>
                    <View style={styles.dividerElement}>
                        <Text style={styles.divider}> / </Text>
                    </View>
                    <View style={styles.rightElement}>
                        <TextInput
                            mode='outlined'
                            style={styles.idInputStyle}
                            label='ID'
                            placeholder="000"
                            placeholderTextColor="#60605e"
                            numeric
                            keyboardType={'numeric'}
                            maxLength={3}
                            value={_id}
                            onChangeText={(v) => {
                                set_Id(v);
                            }}
                        />
                    </View>
                </View>
                
                <Button 
                    style={buttons.button} 
                    labelStyle={buttons.label} 
                    mode='contained' 
                    onPress={() => submitForm()}
                >
                    Login
                </Button>

                <Text style={styles.errorText}>{error}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: -1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }, rowContainer: {
        flexDirection: 'row',
        margin: 25,
    }, leftElement: {
        width: '60%'
    }, dividerElement: {
        width:'10%',
        justifyContent:'center'
    }, divider: {
        fontSize: 24,
        fontWeight: 'bold',
        alignSelf:'center',
    }, rightElement: {
        width: '30%'
    }, errorText: {
        fontSize: 24,
        marginTop: 10,
        color: 'red'
    }, idInputStyle: {
        fontSize: 24,
        justifyContent:'center'
    },  
});

export default Signin;