import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Index from '../screens/Index';
import Signin from '../screens/Signin';
import Register from '../screens/Register';
import TestMain from "../screens/TestMain";
import TestEnhanced from "../screens/TestEnhanced";
import Memory from "../screens/Memory";
import Start from "../screens/Start";
import Results from "../screens/Results";

const Stack = createNativeStackNavigator();

const MainNavigation = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="Index">
                <Stack.Screen 
                    name="Index"
                    component={Index}
                    options={{ title:"Welcome" }}
                />
                <Stack.Screen 
                    name="Signin"
                    component={Signin}
                    options={{ title:"Sign In" }}
                />
                <Stack.Screen 
                    name="Register"
                    component={Register}
                    options={{ title:"Register User" }}
                />
                <Stack.Screen 
                    name="Start"
                    component={Start}
                    options={{ title:"Get Ready" }}
                />
                <Stack.Screen 
                    name="Test"
                    component={TestMain}
                    options={{ title:"" }}
                />
                <Stack.Screen 
                    name="TestEnhanced"
                    component={TestEnhanced}
                    options={{ title:"" }}
                />
                <Stack.Screen 
                    name="Memory"
                    component={Memory}
                    options={{ title:"" }}
                />
                <Stack.Screen 
                    name="Results"
                    component={Results}
                    options={{ title:"Results" }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default MainNavigation;